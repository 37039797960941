import { gql } from "@apollo/client"

const SEND_TRANSFERED_STUDENT_TO_TCU = gql`
mutation sendTransfersTcu($inputs: TCUApplicantTransferRequestNode!){
  sendTransfersTcu(inputs:$inputs){
    status
    code
    message
  }
}
`

export { SEND_TRANSFERED_STUDENT_TO_TCU }
