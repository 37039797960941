import React, { createContext, useState } from "react"

import { Button, Card, CardBody, CardSubtitle, Col, Row } from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import LayoutHelper from "../../../../helpers/LayoutHelper"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import PropTypes from "prop-types"
import { LogedInStudent } from "../../LogedInStudent"
import { useQuery } from "@apollo/client"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "../../../Authentication/ApolloClient"
import { GET_STUDENT_CHANGE_PROGRAM_REQUEST } from "../../Queries/Application/StudentApplicationQueries"
import ChangeProgramModel from "./ChangeProgramModel"
import { sr2URL } from "../../../../helpers/UrlHelper"
import {
  GET_STUDENT,
  GET_STUDENT_BY_USERNAME,
} from "../../../Users/Queries/UserQuery"
import { GET_PROGRAM } from "../../../Programs/Queries/ProgramQuaries"

const breadcrumbItem = [
  {
    title: "Change Program",
    path: "",
    isActive: true,
  },
]

export const StudentChangeProgramContext = createContext() //Create data to transfer to child modal

const StudentChangeProgram = props => {
  const [type, setType] = useState(false)
  const logedIn = LogedInStudent()
  const student = logedIn?.student
  const [isFetchingControlNumber, setIsFetchingControlNumber] = useState(false)
  const study_year =
    student?.studyYear === 0 ? student?.studyYear + 1 : student?.studyYear

  const { loading, error, data } = useQuery(
    GET_STUDENT_CHANGE_PROGRAM_REQUEST,
    {
      client: registrationGraphQLClient,
      skip: !student?.uid,
      variables: {
        studentUid: student?.uid,
      },
    }
  )
  let programChange = data?.getStudentChangePrograms?.data

  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    skip:
      !JSON.parse(localStorage.getItem("authUser"))?.data?.username ||
      !programChange,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "cache-first",
  })
  const studentData = userDetails?.getUserByUsername?.data

  const {
    loading: ProgramLoading,
    error: errorProgram,
    data: Program,
  } = useQuery(GET_PROGRAM, {
    client: registrationGraphQLClient,
    variables: {
      uid: studentData?.student?.programmeUid,
    },
    skip: !studentData?.student?.programmeUid || !programChange,
    fetchPolicy: "no-cache",
  })

  const programName = Program?.getProgram?.data

  const fetchControlNumberForProgramChange = async payload => {
    setIsFetchingControlNumber(true)
    const apiUrl = sr2URL + "billing/other_service_fees"

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })

      setIsFetchingControlNumber(false)
      if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`)
      }

      return await response.json() // Return the response for further processing
    } catch (error) {
      console.error("Error:", error.message)
      throw error // Propagate the error for handling by the caller
    }
  }

  const fetchProgramChangePayment = async payload => {
    setIsFetchingControlNumber(true)

    // Convert payload object to URL query parameters
    const queryParams = new URLSearchParams(payload).toString()
    const apiUrl = `${sr2URL}billing/other_service_fees?${queryParams}`

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })

      setIsFetchingControlNumber(false)

      if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`)
        return null
      }

      return await response.json() // Return the response for further processing
    } catch (error) {
      console.error("Error:", error.message)
      throw error
    }
  }

  function capitalizeFirstLetter(str) {
    if (!str) return "" // Handle empty or undefined input
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const checkPaymentForProgramChange = async () => {
    const payloadForControlNumber = {
      program_code: `${programName?.code}`,
      student_name: `${
        studentData?.firstName +
        " " +
        studentData?.middleName +
        " " +
        studentData?.lastName
      }`,
      study_level: `${programName?.programCategory?.shortName}`,
      program_name: `${programName?.name}`,
      year_of_study: studentData?.student?.studyYear,
      student_status: capitalizeFirstLetter(studentData?.student?.status?.name),
      countrycode: `${studentData?.country?.dialCode}`,
      registration_number: `${studentData?.student?.registrationNumber}`,
      system: "SUA-ESB",
      service_type: "change-program",
      regenerate: 1,
    }

    const payloadForPayment = {
      program_code: `${programName?.code}`,
      study_level: `${programName?.programCategory?.shortName}`,
      program_name: `${programName?.name}`,
      year_of_study: studentData?.student?.studyYear,
      student_status: capitalizeFirstLetter(studentData?.student?.status?.name),
      countrycode: `${studentData?.country?.dialCode}`,
      registration_number: `${studentData?.student?.registrationNumber}`,
      system: "SUA-ESB",
      service_type: "change-program",
    }


    fetchControlNumberForProgramChange(payloadForControlNumber)
      .then(controlNumber => {
        if (controlNumber?.status === 200) {
          console.log(controlNumber?.status)
          console.log("Payload",payloadForPayment);
          fetchProgramChangePayment(payloadForPayment).then(response => {
            console.log(response);
            alert(response);
          })
        }
      })
      .catch(error => {
        console.error("Error fetching control number:", error)
      })
  }

  return (
    <StudentChangeProgramContext.Provider value={{ type, setType }}>
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Change Program Requests"
      >
        {loading ||
          (!student?.uid && (
            <CardBody>
              <PlaceHolderLoader columSize={12} buttonShown={false} rows={5} />
            </CardBody>
          ))}

        {!loading && student?.uid !== undefined ? (
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-1">
                    <Row>
                      <Col className="col-6"></Col>
                      <Col className="col-6">
                        {!programChange && study_year === 1 ? (
                          <ChangeProgramModel student={student} />
                        ) : (
                          " "
                        )}
                        {/*{programChange && study_year === 1 && (*/}
                        {/*  <Button*/}
                        {/*    onClick={checkPaymentForProgramChange}*/}
                        {/*    className="btn btn-sm btn-primary float-end"*/}
                        {/*  >*/}
                        {/*    {isFetchingControlNumber ? (*/}
                        {/*      <>Please wait...</>*/}
                        {/*    ) : (*/}
                        {/*      <>Check program change status</>*/}
                        {/*    )}*/}
                        {/*  </Button>*/}
                        {/*)}*/}
                      </Col>
                    </Row>
                  </CardSubtitle>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="0">From Program</Th>
                            <Th data-priority="0">New Program</Th>
                            <Th data-priority="1">Reason</Th>
                            <Th data-priority="2">Status</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {programChange?.map((c, index) => (
                            <Tr key={c?.uid}>
                              <Th>{c?.currentProgram?.name}</Th>
                              <Th>{c?.newProgram?.name}</Th>
                              <Td>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: c?.reason,
                                  }}
                                />
                              </Td>
                              <Td><strong className="text-danger">Window Closed!</strong></Td>
                              {/*<Td>{c?.approveStatus}</Td>*/}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <CardBody></CardBody>
        )}
      </LayoutHelper>
    </StudentChangeProgramContext.Provider>
  )
}

StudentChangeProgram.propType = {
  uuid: PropTypes.string,
}
export default StudentChangeProgram
