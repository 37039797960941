import React, { createContext, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
import { useFormik } from "formik"
import LayoutHelper from "../../../helpers/LayoutHelper"
import { useLocation, useNavigate } from "react-router-dom"
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper"
import showToast from "../../../helpers/ToastHelper"
import { sr2URL } from "../../../helpers/UrlHelper"

const breadcrumbItem = [
  {
    title: "Student Reg Swapping",
    path: "",
    isActive: true,
  },
]

export const StudentListContext = createContext() // Create data to transfer to child modal

const genderes = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
]
const SwapRegistrationNumberToSR2ForProgramChanged = () => {
  const location = useLocation()
  const [studentData, setStudentData] = useState(false),
    [currentPage, setCurrentPage] = useState(0),
    [offset, setOffset] = useState(0),
    [search, setSearch] = useState(null),
    navigate = useNavigate()


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_registration_number: "",
      registration_number: "",
    },
    validationSchema: Yup.object().shape({
      old_registration_number: Yup.string().required("This field is required"),
      registration_number: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        registration_number: values.registration_number,
        old_registration_number: values.old_registration_number,
      };

      try {
        const result = await swapRegNumberSR2(payload);
        if (result) {
          showToast("Error swapping registration number", error.message);
        }
      } catch (error) {
        showToast("Successfully swapped registration number", "success");
        console.error("Error swapping registration:", error.message);
      } finally {
        setSubmitting(false); // Ensure the submission state is reset
      }
    },
  });

  const swapRegNumberSR2 = async (payload) => {
    console.log(payload)
    const queryParams = new URLSearchParams(payload).toString();
    const apiUrl = `${sr2URL}students/change_program?${queryParams}`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorText = await response.text(); // Retrieve error details from the response
        console.error(`HTTP error! Status: ${response.status}`, errorText);
        showToast("Process failed", "error");
        return null;
      }

      // Return the JSON response for further processing
      return await response.json();
    } catch (error) {
      console.error("Error during API request:", error.message);
      showToast("An error occurred while processing the request", "error");
      throw error; // Re-throw the error for handling at a higher level
    }
  };

  return (
    <StudentListContext.Provider value={{ studentData, setStudentData }}>
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Student Reg No Swapping"
      >
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                    return false
                  }}
                >
                  <div className="modal-body">
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Previous registration number</Label>
                          <Input
                            type="text"
                            name="old_registration_number"
                            className="form-control"
                            id="text"
                            placeholder="Enter  previous registration number(Before program change)"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.old_registration_number || ""}
                            invalid={
                              formik.touched.old_registration_number && formik.errors.old_registration_number
                                ? true
                                : false
                            }
                          />
                          {formik.touched.old_registration_number && formik.errors.old_registration_number ? (
                            <FormFeedback type="invalid">
                              {formik.errors.old_registration_number}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label>New Registration Number (After program change)</Label>
                          <Input
                            type="text"
                            name="registration_number"
                            className="form-control"
                            id="text"
                            placeholder="Enter new registration number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.registration_number || ""}
                            invalid={
                              formik.touched.registration_number &&
                              formik.errors.registration_number
                                ? true
                                : false
                            }
                          />
                          {formik.touched.registration_number &&
                          formik.errors.registration_number ? (
                            <FormFeedback type="invalid">
                              {formik.errors.registration_number}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <SubmitButtonHelper
                    type="primary"
                    name="Submit"
                    formik={formik}
                  />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </StudentListContext.Provider>
  )
}

export default SwapRegistrationNumberToSR2ForProgramChanged
