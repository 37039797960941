//REGISTE
import { gql } from "@apollo/client"

export const GET_COUNTRY = gql`
  query getCountries($pagination: PaginationInput!) {
    getCountries(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
          dialCode
        }
        totalCount
      }
    }
  }
`

export const GET_ACTIVE_HOSTELS = gql`
  query getActiveHostels {
    getAvailableHostels {
      status
      code
      message
      data {
            id
            uid
            name
            code
      }
    }
  }
`

export const GET_HOSTELS = gql`
  query getHostels($pagination: PaginationInput!) {
    getHostels(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
          location
          description
          campuses
          programs
          isAvailable
          blockCount
          availableBlockCount
          roomCount
          availableRoomCount
        }
        totalCount
      }
    }
  }
`
export const GET_ALL_BLOCKS = gql`
  query getBlocks($pagination: PaginationInput!) {
    getBlocks(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
          description
          isAvailable
          hostel {
            id
            uid
            name
            code
            location
            description
            campuses
            programs
            isAvailable
            blockCount
            availableBlockCount
            roomCount
            availableRoomCount
          }
        }
        totalCount
      }
    }
  }
`
export const GET_HOSTEL_BLOCKS = gql`
  query getHostelBlocks($hostelUid: String!) {
    getBlocksByHostel(hostelUid: $hostelUid) {
      status
      code
      message
      data {
        items {
        	id
          uid
          name
          code
          description
          floorCount
          roomCount
          isAvailable
          hostel {
            id
            uid
            name
            code
            location
            description
            isAvailable
            blockCount
            availableBlockCount
            roomCount
            availableRoomCount
          }
        }
      }
    }
  }
`
export const GET_ALL_ROOMS = gql`
  query getRooms($pagination: PaginationInput!) {
    getRooms(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          code
          description
          floorNumber
          isAvailable
          block {
            id
            uid
            name
            code
            description
            floorCount
            roomCount
            isAvailable
            hostel {
              id
              uid
              name
              code
              location
              description
              isAvailable
              roomCount
              availableRoomCount
              blockCount
              availableBlockCount
              campuses
              programs
            }
          }
        }
        totalCount
      }
    }
  }
`
export const GET_BLOCK_ROOMS = gql`
  query getBlockRooms($blockUid: String!) {
    getRoomsByBlock(blockUid: $blockUid) {
      status
      code
      message
      data {
        items {
          id
          uid
          code
          description
          floorNumber
          isAvailable
          block {
            id
            uid
            name
            code
            description
            floorCount
            roomCount
            isAvailable
            hostel {
              id
              uid
              name
              code
              location
              description
              isAvailable
              roomCount
              availableRoomCount
              blockCount
              availableBlockCount
              campuses
              programs
            }
          }
        }
        totalCount
      }
    }
  }
`
export const GET_ROOM_CAPACITIES = gql`
  query getRoomCapacities($pagination: PaginationInput!) {
    getRoomCapacities(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          academicYear
          programCategory
          gender
          capacity
          vacant
          isReserved
          room{
            uid
            code
            floorNumber
            isAvailable
            block {
              uid
              name
              code
              isAvailable
              hostel {
                uid
                name
                code
                location
                description
                isAvailable
                roomCount
                availableRoomCount
                blockCount
                availableBlockCount
                campuses
                programs
              }
            }
          }
        }
        totalCount
      }
    }
  }
`
export const GET_VACANT_ROOM_CAPACITIES = gql`
  query getVacantRoomCapacities($input: VacantRoomCapacityDtoInput!) {
    getVacantRoomCapacities(input: $input) {
      status
      code
      message
      data {
        items {
          uid
          academicYear
          programCategory
          gender
          capacity
          vacant
          isReserved
          room{
            uid
            code
            floorNumber
            isAvailable
            block {
              uid
              name
              code
              isAvailable
              hostel {
                uid
                name
                code
                location
                description
                isAvailable
                roomCount
                availableRoomCount
                blockCount
                availableBlockCount
                campuses
                programs
              }
            }
          }
        }
        totalCount
      }
    }
  }
`
export const GET_ROOM_ALLOCATIONS = gql`
  query getRoomAllocations($pagination: PaginationInput!) {
    getRoomAllocations(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          roomCapacity{
            uid
            academicYear
            programCategory
            gender
            capacity
            vacant
            isReserved
            room{
              uid
              code
              floorNumber
              isAvailable
              block {
                uid
                name
                code
                isAvailable
                hostel {
                  uid
                  name
                  code
                  location
                  description
                  isAvailable
                  roomCount
                  availableRoomCount
                  blockCount
                  availableBlockCount
                  campuses
                  programs
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`
export const SEARCH_CONTINUING_STUDENTS = gql`
  query getContinuingStudents($pagination: PaginationInput!) {
    getContinuingStudents(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          studentUid
          roomCapacity{
            uid
            academicYear
            programCategory
            gender
            capacity
            vacant
            isReserved
            room{
              uid
              code
              floorNumber
              isAvailable
              block {
                uid
                name
                code
                isAvailable
                hostel {
                  uid
                  name
                  code
                  location
                  description
                  isAvailable
                  roomCount
                  availableRoomCount
                  blockCount
                  availableBlockCount
                  campuses
                  programs
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`
export const GET_STUDENT_ROOM_REQUESTS = gql`
  query getCurrentStudentRoomRequests($studentUid: String!) {
    getCurrentStudentRoomRequests(studentUid: $studentUid) {
      status
      code
      message
      data {
        uid
        academicYear
        status
        isAuto
        reason
        roomAllocation {
          isAuto
          isPublished
          isPaid
          isExpired
          expiresAt
          roomCapacity {
            uid
            academicYear
            programCategory
            gender
            capacity
            vacant
            isReserved
            room{
              uid
              code
              floorNumber
              isAvailable
              block {
                uid
                name
                code
                isAvailable
                hostel {
                  uid
                  name
                  code
                  location
                  description
                  isAvailable
                  roomCount
                  availableRoomCount
                  blockCount
                  availableBlockCount
                  campuses
                  programs
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_ACCOMMODATION_FEE_STRUCTURE = gql`
  query getAccommodationFeeStructure($input: FeeStructureInput!) {
    getAccommodationFeeStructure(input: $input) {
      status
      code
      message
      data {
        amount
        currency
      }
    }
  }
`
export const GET_ACCOMMODATION_CONTROL_NUMBER = gql`
  query getAccommodationControlNumber($input: GetControlNumberInput!) {
    getAccommodationControlNumber(input: $input) {
      status
      code
      message
      data {
        amount
        currency
        controlNumber
      }
    }
  }
`
export const GET_STUDENT_ROOM_ALLOCATIONS = gql`
  query getStudentRoomAllocations($studentUid: String!) {
    getStudentRoomAllocations(studentUid: $studentUid) {
      status
      code
      message
      data {
        isPaid
        boardings {
          uid
          remarks
          isIn
          boardingDate
          items {
            name
            value
          }
        }
        roomRequest {
          uid
          academicYear
          status
          isAuto
          reason
        }
        roomCapacity {
          uid
          academicYear
          programCategory
          gender
          capacity
          vacant
          isReserved
          room{
            uid
            code
            floorNumber
            isAvailable
            block {
              uid
              name
              code
              isAvailable
              hostel {
                uid
                name
                code
                location
                description
                isAvailable
                roomCount
                availableRoomCount
                blockCount
                availableBlockCount
                campuses
                programs
              }
            }
          }
        }
      }
    }
  }
`
export const GET_STUDENT_REQUESTS_AND_ALLOCATIONS = gql`
  query getCurrentRoomRequests($pagination: PaginationContextInput!) {
    getCurrentRoomRequests(pagination: $pagination){
      status
      code
      message
      data {
        items{
          uid
          status
          isAuto
          reason
          programCategory
          studentUid
          student {
            registrationNumber
            fullName
            gender
            phoneNumber
          }
          roomAllocation{
            uid
            isPublished
            isAuto
            isPaid
            isExpired
            expiresAt
            boardings {
              uid
              remarks
              isIn
              boardingDate
              items {
                name
                value
              }
            }
            roomCapacity{
              uid
              programCategory
              gender
              capacity
              vacant
              isReserved
              room{
                uid
                code
                floorNumber
                isAvailable
                block{
                  uid
                  name
                  code
                  hostel{
                    uid
                    name
                    code
                    campuses
                    programs
                  }
                }
              }
            }
          }
        }
        totalCount
        searchCount
      }
    }
  }
`
export const VERIFY_ACCOMMODATION_PAYMENT = gql`
  query verifyAccommodationPayment($input: VerifyPaymentInput!) {
    verifyAccommodationPayment(input: $input) {
      status
      code
      message
      data 
    }
  }
`
export const GET_ITEMS = gql`
  query getItems($pagination: PaginationInput!) {
    getItems(pagination: $pagination) {
      status
      code
      message
      data {
        items{
          uid
          name
        }
        totalCount
      }
    }
  }
`

export const GENERATE_ALLOCATION_PAYMENT_REPORT_XLS = gql`
  query GetSemesterCourseResults($academicYear: String!, $hostel: String) {
    getRoomAllocationPaymentReportsByAcademicYear(academicYear: $academicYear, hostelCode: $hostel){
        status
        code
        message
        data{
          base64Data
          fileName
        }
    }
  }
`;


// export { GET_COUNTRY, GET_HOSTELS, GET_ALL_BLOCKS, GET_HOSTEL_BLOCKS  
