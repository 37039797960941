import { Link } from "react-router-dom"
import React from "react"

const NHIFLinks = ({ hasAuthority }) => {
  return (
    <>
      {hasAuthority("VIEW_NHIF_MENU") && (
        <li>
          <Link to="/nhif/application">
            <i className="bx bx-id-card"></i>
            <span>NHIF Registration</span>
          </Link>
        </li>
      )}
    </>
  )
}

export default NHIFLinks
