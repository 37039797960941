import React, { createContext, useState, useEffect } from "react"
import ApexCharts from "react-apexcharts"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Button,
  Badge,
  Progress,
  Label,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import LayoutHelper from "helpers/LayoutHelper"
import { useLazyQuery, useQuery, useMutation } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useParams } from "react-router"
import { GenerateTablePDF } from "../../../../src/helpers/PdfTableHelper"
import {
  GET_COURSE_ALLOCATION,
  DELETE_STUDENT_RESULT_SUMMARY,
  GET_STUDENT_COURSE_ALLOCATIONS,
} from "../Queries/InstructorCoursesQueries"

import CourseExcelDownloder from "./CourseExcelDownloder"
import UploadCourseResult from "./UploadCourseResult"
import {
  GET_EXAM_RESULT_SUMMARIES,
  GET_RESULTS,
} from "../Queries/CourseWorkResults"
import { GENERATE_SEMESTER_COURSE_RESULTS_XLS } from "../../Reports/Results/Queries/ResultReportQueries"
import { DownloadExcelFile } from "../../../helpers/DownloadExcelFile"
import DropDownActionHelper from "../../../helpers/DropDownActionHelper"
import { showDeleteWarning } from "../../../helpers/DeleteWaringHelper"
import { forwardStatus } from "helpers/ForwardStatus"
import Select from "react-select"

const breadcrumbItem = [
  {
    title: "Result Upload",
    path: "",
    isActive: true,
  },
]

const examTypeOptions = [
  {
    value: 1,
    label: "First Sitting",
  },
  {
    value: 2,
    label: "Probation",
  },
  {
    value: 3,
    label: "Third Attempt",
  },
]

export const InstructorCourseDetailsContext = createContext()

const InstructorCourseDetail = () => {
  const { uid } = useParams()
  const [isLoading, setIsLoading] = useState(false) // State to track loading
  const [progress, setProgress] = useState(0) // State to track progress

  const [modalOpen, setModalOpen] = useState(false)

  const closeModal = () => {
    setModalOpen(false) // handle Excel download Close Modal
  }

  const [uploadResultOnline, setUploadResultOnline] = useState(true)
  const [selectedStudentResult, setSelectedStudentResult] = useState(null) //Get data for deleting

  const [IsUploadResultModalOpened, setIsUploadResultModalOpened] =
    useState(false)

  const closeUploadResultModal = () => {
    setIsUploadResultModalOpened(false) // handle upload Close result Modal
  }

  const handleDeleteStudentResult = deleteData => {
    // alert(JSON.stringify(deleteData))
    setSelectedStudentResult(deleteData)
    showDeleteWarning(() => {
      performDataDeletion()
    })
  }

  const performDataDeletion = async () => {
    try {
      const response = await deleteExamResultSummary()

      // alert(response)
      showToast(
        response.data.removeExamResultSummary.message,
        response.data.removeExamResultSummary.code === 8000
          ? "success"
          : "error"
      )
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  const [examCategoryID, setExamCategoryID] = useState(null)

  const [examCategoryName, setExamCategoryName] = useState(null)

  const [assessmentNumber, setAssessmentNumber] = useState(null)

  const [canExceedBy, setCanExceedBy] = useState(null)

  const [allocationUID, setAllocationUID] = useState(null)

  const options = [] // Options for Mark out

  for (let i = 1; i <= 100; i++) {
    options.push({ value: i, label: parseInt(`${i}`) }) // Mark out Of
  }

  const weights = []

  for (let i = 1; i <= 100; i++) {
    weights.push({ value: i, label: parseInt(`${i}`) }) // Weight
  }

  const handleExamCategoryID = Id => {
    setExamCategoryID(Id)
  }

  const handleExamCategoryName = name => {
    setExamCategoryName(name)
  }

  function capitalizeFirstLetter(str) {
    if (!str) return "" // Return an empty string if str is undefined or null
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const handleAssessmentNumber = number => {
    setAssessmentNumber(number)
  }

  const [currentPage, setCurrentPage] = useState(limit)
  const [exam, setExam] = useState(null)
  const [pageSize, setPageSize] = useState(0)
  const [examType, setExamType] = useState("")
  const pagination = { offset: currentPage, limit: limit, search: null }
  const startIndex = currentPage * pageSize

  const {
    loading: courseLoading,
    error: courseError,
    data: courseData,
    refetch,
  } = useQuery(GET_COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
    variables: {
      uid: uid ? uid : "",
    },
    fetchPolicy: "network-only",
  })

  // console.log("Data: ", courseData)

  const forward_status =
    courseData?.getCourseAllocation?.data?.programCourse?.forwardStatus
  const programCourseId =
    courseData?.getCourseAllocation?.data?.programCourse?.id

  // const programCourseId =
  // courseData?.getCourseAllocation?.data?.programCourse?.id

  // alert(JSON.stringify(courseData))

  const [deleteExamResultSummary] = useMutation(DELETE_STUDENT_RESULT_SUMMARY, {
    client: registrationGraphQLClient,
    variables: {
      input: {
        programCourseId: selectedStudentResult?.programCourseId
          ? selectedStudentResult?.programCourseId.toString()
          : "-1",
        studentUid: selectedStudentResult?.studentUid
          ? selectedStudentResult?.studentUid.toString()
          : "-1",
      },
    },
    refetchQueries: [
      {
        query: GET_EXAM_RESULT_SUMMARIES,
        variables: {
          searchCriteria: {
            programCourseId: programCourseId
              ? programCourseId.toString()
              : "-1",
          },
        },
      },
    ],
  })

  // if(programCourseId){// check if programCourseId has value
  const {
    loading: resultLoading,
    error: resultError,
    data: resultData,
    refetch: resultRefeching,
  } = useQuery(GET_RESULTS, {
    client: registrationGraphQLClient,
    variables: {
      searchCriteria: {
        programCourseId: programCourseId ? programCourseId.toString() : "-1",
      },
    },
    fetchPolicy: "network-only",
  })

  const {
    loading: resultSummaryLoading,
    error: resultSummaryError,
    data: resultSummaryData,
    refetch: resultSummaryRefeching,
  } = useQuery(GET_EXAM_RESULT_SUMMARIES, {
    client: registrationGraphQLClient,
    variables: {
      searchCriteria: {
        programCourseId: programCourseId ? programCourseId.toString() : "-1",
      },
    },
    fetchPolicy: "network-only",
  })
  // }

  const downloadExamSummaryPdf = () => {
    let title = ""
    title = "Exam Summary Report"
    if (resultSummaryData?.getExamResultSummaries?.data.length > 0) {
      const std = resultSummaryData?.getExamResultSummaries?.data
      const columns = [
        "S/No",
        "Student Name",
        "Registration number",
        "Sex",
        "Total Course Work",
        "Total UE",
        "Total Score",
        "Grade",
        "Grade Point",
        "Remark",
      ]
      let studentData = []
      if (std) {
        for (let f = 0; f < std?.length; f++) {
          let s = std[f]
          studentData.push([
            f + 1,
            s.firstName + " " + s.lastName,
            s.registrationNumber,
            s.gender,
            s.cwScore ? s.cwScore.toFixed(2) : "-",
            s.ueScore ? s.ueScore.toFixed(2) : "-",
            s.totalScore ? s.totalScore.toFixed(2) : "-",
            s.grade,
            s.gradePoint,
            s.gradeRemark,
          ])
        }
      }
      GenerateTablePDF(columns, studentData, title, [], "p")
    }
  }

  let [
    getCourseResults,
    { loading: reportLoading, error: reportError, data: reportData },
  ] = useLazyQuery(GENERATE_SEMESTER_COURSE_RESULTS_XLS, {
    client: registrationGraphQLClient,
    fetchPolicy: "no-cache",
  })
  const downloadCourseResultReport = () => {
    setIsLoading(true) // Show progress bar when starting the report generation
    setProgress(0) // Reset progress bar to 0%

    const TIMEOUT_LIMIT = 300000 // 5 minutes
    const PROGRESS_INTERVAL = 100 // Update progress every 100ms
    const PROGRESS_INCREMENT = 10 // Increment progress by 10% for each step

    const timeoutId = setTimeout(() => {
      setIsLoading(false) // Hide progress bar on timeout
      showToast(
        "The report generation is taking longer than expected. Please wait or try refreshing the page.",
        "warning"
      )
    }, TIMEOUT_LIMIT)

    const progressIntervalId = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress =
          prevProgress + (PROGRESS_INTERVAL / TIMEOUT_LIMIT) * 100
        return newProgress >= 100 ? 100 : newProgress
      })
    }, PROGRESS_INTERVAL)

    getCourseResults({
      variables: {
        programCourseUid:
          courseData?.getCourseAllocation?.data?.programCourse?.uid,
      },
      client: registrationGraphQLClient,
      onCompleted: ({ getSemesterCourseResults }) => {
        clearTimeout(timeoutId)
        clearInterval(progressIntervalId)
        setIsLoading(false)
        setProgress(100)

        showToast(
          getSemesterCourseResults.message,
          getSemesterCourseResults.code === 8000 ? "success" : "error"
        )

        if (getSemesterCourseResults.code === 8000) {
          DownloadExcelFile(
            getSemesterCourseResults?.data?.base64Data,
            getSemesterCourseResults?.data?.fileName
          )
        }
      },
      onError: error => {
        clearTimeout(timeoutId)
        clearInterval(progressIntervalId)
        setIsLoading(false)
        showToast("An error occurred. Please try again.", "error")
        console.error("Mutation error:", error)
      },
    })
  }

  const downloadExamCourseWorkPdf = () => {
    let title = ""
    title = "Exam CourseWork Report"
    if (resultData?.getExamCourseWorkResults?.data.length > 0) {
      let data = resultData?.getExamCourseWorkResults?.data
      const groupedData = {}
      data.forEach(record => {
        const {
          studentUid,
          registrationNumber,
          firstName,
          middleName,
          lastName,
          score,
          weight,
          source,
          assessmentNumber,
          examCategoryId,
          examCategoryCode,
          examCategoryName,
          overallMarks,
        } = record

        if (!groupedData[studentUid]) {
          groupedData[studentUid] = []
        }
        groupedData[studentUid].push({
          score,
          registrationNumber,
          firstName,
          middleName,
          lastName,
          weight,
          source,
          assessmentNumber,
          examCategoryId,
          examCategoryCode,
          examCategoryName,
          overallMarks,
        })
      })

      const columns = [
        "S/No",
        "Student Name",
        "Registration number",
        "Course Work",
      ]

      let studentData = []

      let counter = 0
      Object.entries(groupedData).forEach(
        ([
          studentUid,
          registrationNumber,
          scores,
          firstName,
          middleName,
          lastName,
        ]) => {
          let studentuid = studentUid

          let courseWork = ""
          scores.forEach(
            (
              {
                score,
                registrationNumber,
                weight,
                source,
                assessmentNumber,
                examCategoryId,
                examCategoryCode,
                examCategoryName,
                overallMarks,
              },
              index
            ) => {
              courseWork +=
                examCategoryCode + " " + assessmentNumber + ": " + score + "| "
            }
          )

          studentData.push([
            counter + 1,
            lastName.toUpperCase() +
              ", " +
              capitalizeFirstLetter(firstName) +
              " " +
              capitalizeFirstLetter(middleName),
            registrationNumber,
            courseWork,
          ])
        }
      )

      GenerateTablePDF(columns, studentData, title, [], "p")
    }
  }

  const assessments =
      courseData?.getCourseAllocation?.data?.programCourse
        ?.programCourseAssessments,
    resultSummaries = resultSummaryData?.getExamResultSummaries?.data

  let data = resultSummaryData?.getExamResultSummaries?.data

  // alert(JSON.stringify(data))

  const [gradeCounts, setGradeCounts] = useState({ grades: [], counts: [] })

  useEffect(() => {
    // Sample JSON data containing exam results
    const examResults = [
      // Sample exam results data here...
    ]

    // Initialize an object to store grade counts
    const counts = {}

    // Loop through the exam results and count occurrences of each grade
    examResults.forEach(result => {
      const { grade } = result
      // If the grade exists in the counts object, increment its count, otherwise initialize it to 1
      counts[grade] = (counts[grade] || 0) + 1
    })

    // Convert the counts object into two arrays: one for grades and one for counts
    const grades = Object.keys(counts)
    const countsArray = Object.values(counts)

    // Update state with the arrays
    setGradeCounts({ grades, counts: countsArray })
  }, []) // Empty dependency array to ensure useEffect runs only once after initial render

  const examSummaryPieChart = {
    series: [10, 34, 20, 9, 34, 20, 9],
    options: {
      chart: {
        type: "pie",
      },
      labels: ["A", "B+", "B", "C", "D", "E", "F"],
      colors: [
        "#6495ED",
        "#800080",
        "#00FFFF",
        "#FF4500",
        "#20B2AA",
        "#8B4513",
        "#8A2BE2",
      ],
      legend: {
        position: "bottom",
      },
    },
  }

  return (
    <InstructorCourseDetailsContext.Provider
      value={{
        examType,
        setExamType,
        examCategoryID,
        examCategoryName,
        assessmentNumber,
        allocationUID,
        uploadResultOnline,
        setUploadResultOnline,
        assessments,
        uid,
        courseData,
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle={
          courseData?.getCourseAllocation?.data?.programCourse?.programSemester
            ?.academicYear?.name
            ? "Course Results:  " +
              courseData?.getCourseAllocation?.data?.programCourse
                ?.programSemester?.academicYear?.name
            : "Course Results:  "
        }
      >
        {courseLoading ? (
          <PlaceHolderLoader rows={4} type="table" columSize={12} />
        ) : (
          <>
            {courseData?.getCourseAllocation?.data != "" ? (
              <>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody className="">
                        <CourseExcelDownloder
                          isOpen={modalOpen}
                          closeModal={closeModal}
                        />
                        <UploadCourseResult
                          isOpenUploadResultModal={IsUploadResultModalOpened}
                          closeUploadResultModal={closeUploadResultModal}
                        />
                        <CardSubtitle className="mb-0"></CardSubtitle>
                        <Row className="text-uppercase">
                          <Col md={12}>
                            <Table>
                              <Thead className="border-bottom">
                                <Tr>
                                  <Th className="text-center">Study Year</Th>
                                  <Th className="text-center">Semester</Th>
                                  <Th className="text-center">Course Ante</Th>
                                  <Th className="text-center">Course Name</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td className="text-center">
                                    <strong>
                                      {
                                        courseData?.getCourseAllocation?.data
                                          ?.programCourse?.programSemester
                                          ?.studyYear
                                      }
                                    </strong>
                                  </Td>
                                  <Td className="text-center font-weight-bolder">
                                    <strong>
                                      {
                                        courseData?.getCourseAllocation?.data
                                          ?.programCourse?.programSemester
                                          ?.semester
                                      }
                                    </strong>
                                  </Td>
                                  <Td className="text-center">
                                    <strong>
                                      {
                                        courseData?.getCourseAllocation?.data
                                          ?.programCourse?.course?.code
                                      }
                                    </strong>
                                  </Td>
                                  <Td className="text-center">
                                    {
                                      courseData?.getCourseAllocation?.data
                                        ?.programCourse?.course?.name
                                    }
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <Label>Choose examination type</Label>
                        <Select
                          // value={selectedMulti2}
                          styles={{
                            menu: base => ({
                              ...base,
                              position: "absolute",
                              zIndex: 9999,
                            }),
                          }}
                          onChange={e => {
                            setExam(e.value)
                          }}
                          options={examTypeOptions}
                          className="select2-selection"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {forward_status === 0 ? (
                  <>
                    {exam === 1 && (
                      <Row className="text-uppercase">
                        {assessments !== "" && (
                          <>
                            {assessments?.map((course, index) => (
                              <Col
                                xl={3}
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                key={index}
                              >
                                <Card className="text-center primary text-muted">
                                  <CardBody>
                                    <h5 className="font-size-15  mb-0">
                                      <b>{course?.examCategory?.name}</b>
                                    </h5>
                                    <small>{course?.maximumScore}%</small>

                                    <div>
                                      {Array.from(
                                        { length: course?.minimumExams },
                                        (_, buttonIndex) => (
                                          <button
                                            onClick={() => (
                                              setAllocationUID(uid),
                                              handleExamCategoryName(
                                                courseData?.getCourseAllocation
                                                  ?.data?.programCourse?.course
                                                  ?.code +
                                                  "_" +
                                                  course?.examCategory?.code +
                                                  "_" +
                                                  (buttonIndex + 1)
                                              ),
                                              handleAssessmentNumber(
                                                buttonIndex + 1
                                              ),
                                              setModalOpen(true),
                                              handleExamCategoryID(
                                                course?.examCategory?.id
                                              )
                                            )}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            className="btn btn-outline-success btn-sm"
                                            key={buttonIndex}
                                          >
                                            <i className="fa fa-download"></i>
                                            <b>
                                              {" "}
                                              {course?.examCategory?.code}{" "}
                                              {buttonIndex + 1}
                                            </b>
                                          </button>
                                        )
                                      )}
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </>
                        )}
                      </Row>
                    )}
                  </>
                ) : forward_status == null ? (
                  <>
                    {exam === 1 && (
                      <Row className="text-uppercase">
                        {assessments !== "" && (
                          <>
                            {assessments?.map((course, index) => (
                              <Col
                                xl={3}
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                key={index}
                              >
                                <Card className="text-center primary text-muted">
                                  <CardBody>
                                    <h5 className="font-size-15  mb-0">
                                      <b>{course?.examCategory?.name}</b>
                                    </h5>
                                    <small>{course?.maximumScore}%</small>

                                    <div>
                                      {Array.from(
                                        { length: course?.minimumExams },
                                        (_, buttonIndex) => (
                                          <button
                                            onClick={() => (
                                              setAllocationUID(uid),
                                              handleExamCategoryName(
                                                courseData?.getCourseAllocation
                                                  ?.data?.programCourse?.course
                                                  ?.code +
                                                  "_" +
                                                  course?.examCategory?.code +
                                                  "_" +
                                                  (buttonIndex + 1)
                                              ),
                                              handleAssessmentNumber(
                                                buttonIndex + 1
                                              ),
                                              setModalOpen(true),
                                              handleExamCategoryID(
                                                course?.examCategory?.id
                                              )
                                            )}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            className="btn btn-outline-success btn-sm"
                                            key={buttonIndex}
                                          >
                                            <i className="fa fa-download"></i>
                                            <b>
                                              {" "}
                                              {course?.examCategory?.code}{" "}
                                              {buttonIndex + 1}
                                            </b>
                                          </button>
                                        )
                                      )}
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </>
                        )}
                      </Row>
                    )}
                  </>
                ) : (
                  <Row>
                    {exam === 2 && (
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <CourseExcelDownloder
                              isOpen={modalOpen}
                              closeModal={closeModal}
                            />
                            <Row>
                              <Col md={6}>
                                <button
                                  className="btn btn-dark btn-sm"
                                  onClick={() => {
                                    setAllocationUID(uid)
                                    setModalOpen(true)
                                    setExamType("PROBATION")
                                  }}
                                >
                                  <i className="fa fa-download"></i> Probation
                                  sheet
                                </button>
                              </Col>
                              <Col md={6}>
                                <Button
                                  color="primary"
                                  className="btn btn-sm float-end mb-2"
                                  onClick={() => {
                                    setIsUploadResultModalOpened(true)
                                  }}
                                >
                                  <i className="fa fa-upload"></i> Upload
                                  Probation Result
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    )}
                  </Row>
                )}

                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <CardSubtitle className="mb-1">
                          <Row>
                            <Col className="col-lg-6 col-md-6 col-sm-6"></Col>
                            <Col className="col-lg-6 col-sm-6 col-md-6 pull-right"></Col>
                          </Row>
                        </CardSubtitle>

                        {!resultSummaryLoading && (
                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                            >
                              <Row>
                                <Col lg={6}>
                                  <Button
                                    className="btn btn-success btn-sm"
                                    onClick={downloadExamSummaryPdf}
                                  >
                                    <i className="fa fa-file-pdf"></i> Exam
                                    Summary Report
                                  </Button>{" "}
                                  <Button
                                    className="btn btn-info btn-sm"
                                    disabled={reportLoading}
                                    onClick={downloadCourseResultReport}
                                  >
                                    {reportLoading ? (
                                      <>
                                        <div className="my-3">
                                          <Progress
                                            value={progress.toFixed(2)}
                                            style={{ background: "none" }}
                                          />
                                          <p
                                            className="text-center mt-2"
                                            style={{ color: "white" }}
                                          >
                                            <strong style={{ color: "white" }}>
                                              {progress.toFixed(2)}% complete.
                                              Please wait...
                                            </strong>
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <i className="fa fa-file-excel"></i>{" "}
                                        Course Results Report
                                      </>
                                    )}
                                  </Button>
                                </Col>
                                <Col lg={6}>
                                  {forward_status == 0 ||
                                  forward_status == null ? (
                                    <Button
                                      color="primary"
                                      className="btn float-end mb-2"
                                      onClick={() => {
                                        setIsUploadResultModalOpened(true)
                                      }}
                                    >
                                      <i className="fa fa-upload"></i> Upload
                                      Result (Excel / Online)
                                    </Button>
                                  ) : (
                                    <Row className="float-end">
                                      <Col lg={6}>
                                        <strong>Forward Status: </strong>
                                      </Col>
                                      <Col lg={6}>
                                        {forwardStatus(forward_status)}
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              </Row>

                              {/* )
                            } */}
                              <Table
                                id="tech-companies-1"
                                className="table table-striped table-bordered"
                                style={{ border: "2px solid #63a69f" }}
                              >
                                <Thead>
                                  <Tr>
                                    <Th data-priority="1">S/N</Th>
                                    <Th data-priority="1">Full Name</Th>
                                    <Th data-priority="1">Registration No</Th>
                                    <Th data-priority="1">CW-T</Th>
                                    <Th data-priority="1">CW-P</Th>
                                    <Th data-priority="1">CW-TOTAL</Th>
                                    <Th data-priority="1">UE-T</Th>
                                    <Th data-priority="1">UE-P</Th>
                                    <Th data-priority="1">UE-TOTAL</Th>
                                    <Th data-priority="1">Total Marks</Th>
                                    <Th data-priority="1">Grade</Th>
                                    <Th data-priority="1">Grade Point</Th>
                                    <Th data-priority="1">Remark</Th>
                                    <Th data-priority="1">Action</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {resultSummaries?.map((student, index) => (
                                    <Tr key={student?.studentUid}>
                                      <Td>{startIndex + index + 1}</Td>
                                      <Td>
                                        {student?.lastName.toUpperCase()},{" "}
                                        {capitalizeFirstLetter(
                                          student?.firstName
                                        )}{" "}
                                        {capitalizeFirstLetter(
                                          student?.middleName
                                        )}
                                      </Td>
                                      <Td>{student?.registrationNumber}</Td>
                                      <Td>
                                        {student?.cwTheory
                                          ? student?.cwTheory.toFixed(2)
                                          : "-"}
                                      </Td>
                                      <Td>
                                        {student?.cwPractical
                                          ? student?.cwPractical.toFixed(2)
                                          : "-"}
                                      </Td>
                                      <Td>
                                        {student?.cwScore
                                          ? student?.cwScore.toFixed(2)
                                          : "-"}
                                      </Td>
                                      <Td>
                                        {student?.ueTheory
                                          ? student?.ueTheory.toFixed(2)
                                          : "-"}
                                      </Td>
                                      <Td>
                                        {student?.uePractical
                                          ? student?.uePractical.toFixed(2)
                                          : "-"}
                                      </Td>
                                      <Td>
                                        {student?.ueScore
                                          ? student?.ueScore.toFixed(2)
                                          : "-"}
                                      </Td>
                                      <Td>
                                        {student?.totalScore
                                          ? student?.totalScore.toFixed(2)
                                          : "-"}
                                      </Td>
                                      <Td>{student?.grade}</Td>
                                      <Td>
                                        {student?.gradePoint
                                          ? student?.gradePoint
                                          : "-"}
                                      </Td>
                                      <Td>{student?.gradeRemark}</Td>
                                      <Td>
                                        {forward_status == 0 ? (
                                          <DropDownActionHelper
                                            data={student}
                                            onDelete={handleDeleteStudentResult}
                                          />
                                        ) : (
                                          <div className="alert alert-info">
                                            {" "}
                                            *{" "}
                                          </div>
                                        )}
                                      </Td>
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </div>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col md={12} lg={12}>
                  <Card>
                    <CardBody>
                      <div className="alert alert-info">
                        No Course assessment defined, please contact your Head
                        of Department!
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
      </LayoutHelper>
    </InstructorCourseDetailsContext.Provider>
  )
}

export default InstructorCourseDetail
