import React, { useState, useEffect } from "react"
import LayoutHelper from "helpers/LayoutHelper"
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormFeedback,
  Label,
  Progress,
} from "reactstrap"
import Select from "react-select"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ALL_PROGRAMS } from "../../../Programs/Queries/ProgramQuaries"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "../../../Authentication/ApolloClient"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import showToast from "../../../../helpers/ToastHelper"
import { DownloadExcelFile } from "../../../../helpers/DownloadExcelFile"
import { SWAP_STUDENT_REG_NUMBER } from "../../../Students/Mutations/UpdateStudentMutation"

const UpdatedRegNo = () => {
  const [swapping] = useMutation(SWAP_STUDENT_REG_NUMBER, {
    client: uaaGraphQLClient,
  })
  const header = {
    title: "Swap Student Registration Number",
    breadcrumItem: [
      { title: "Swap Student Registration Number", path: "", isActive: true },
    ],
  }

  const options = [
    {
      label: "Direct",
      value: "D",
    },
    {
      label: "Equivalent",
      value: "E",
    },
  ]

  const pagination = { offset: 0, limit: 5000, search: null }

  const { loading: programLoading, data: programData } = useQuery(
    GET_ALL_PROGRAMS,
    {
      client: registrationGraphQLClient,
      variables: { pagination },
    }
  )

  let programs = programData?.getAllPrograms?.data?.items || []

  const search = (value, type) => {
    if (type === "program") {
      if (value !== undefined && value.length >= 4) {
        searchPrograms({
          variables: { pagination: { ...pagination, search: value } },
        })
      }
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentRegistrationNumber: "",
      entryCategoryCode: "",
      programUidToMoveTo: "",
    },
    validationSchema: Yup.object().shape({
      programUidToMoveTo: Yup.string().required("Program is required"),
      entryCategoryCode: Yup.string().required("This field is required"),
      currentRegistrationNumber: Yup.string().required(
        "This field is required"
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      swapping({
        variables: {
          inputs: { ...values },
        },
        onCompleted: ({ swapStudentRegistrationNumber }) => {
          if (swapStudentRegistrationNumber?.code === 8000) {
            setSubmitting(false)
          }
          showToast(
            swapStudentRegistrationNumber.message,
            swapStudentRegistrationNumber.code === 8000 ? "success" : "error"
          )
          setSubmitting(false)
        },
        onError: error => {
          console.error("Mutation error:", error)
          showToast(
            "An error occurred. Please wait before retrying again.",
            "error"
          )
          setSubmitting(true)
        },
      })
    },
  })

  return (
    <LayoutHelper
      pageTitle={header.title}
      breadcrumbItem={header.breadcrumItem}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <label htmlFor="firstName">
                        Current registration number
                      </label>
                      <Input
                        type="text"
                        name="currentRegistrationNumber"
                        className="form-control"
                        id="currentRegistrationNumbercurrentRegistrationNumber"
                        placeholder="Enter registration number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.currentRegistrationNumber || ""
                        }
                        invalid={
                          !!(
                            validation.touched.currentRegistrationNumber &&
                            validation.errors.currentRegistrationNumber
                          )
                        }
                      />
                      {validation.touched.currentRegistrationNumber &&
                      validation.errors.currentRegistrationNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.currentRegistrationNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                      <Label>Program Name(To move to)</Label>
                      <Select
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                        onChange={e => {
                          if (e) {
                            validation.setFieldValue(
                              "programUidToMoveTo",
                              e.value
                            )
                          } else {
                            validation.setFieldValue("programUidToMoveTo", "")
                          }
                        }}
                        onInputChange={e => search(e, "program")}
                        options={programs.map(pro => ({
                          value: pro.uid,
                          duration: pro.duration,
                          label: pro.name + " (" + pro.registrationCode + ")",
                        }))}
                        className="select2-selection"
                        isClearable={true}
                        isLoading={programLoading}
                      />
                      <Input
                        name="programUidToMoveTo"
                        type="hidden"
                        onChange={validation.handleChange}
                        value={validation.values.programUidToMoveTo}
                        invalid={
                          !!(
                            validation.touched.programUidToMoveTo &&
                            validation.errors.programUidToMoveTo
                          )
                        }
                      />
                      {validation.touched.programUidToMoveTo &&
                      validation.errors.programUidToMoveTo ? (
                        <FormFeedback type="invalid">
                          {validation.errors.programUidToMoveTo}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                      <Label>Entry category</Label>
                      <Select
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                        onChange={e => {
                          if (e) {
                            validation.setFieldValue(
                              "entryCategoryCode",
                              e.value
                            )
                          } else {
                            validation.setFieldValue("entryCategoryCode", "")
                          }
                        }}
                        onInputChange={e => search(e, "program")}
                        options={options}
                        className="select2-selection"
                        isClearable={true}
                        isLoading={programLoading}
                      />
                      <Input
                        name="entryCategoryCode"
                        type="hidden"
                        onChange={validation.handleChange}
                        value={validation.values.entryCategoryCode}
                        invalid={
                          !!(
                            validation.touched.entryCategoryCode &&
                            validation.errors.entryCategoryCode
                          )
                        }
                      />
                      {validation.touched.entryCategoryCode &&
                      validation.errors.entryCategoryCode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.entryCategoryCode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="float-end">
                      <SubmitButtonHelper
                        type={"primary"}
                        name={"Submit"}
                        formik={validation}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default UpdatedRegNo
