import React from "react";
import { Routes, Route } from "react-router-dom";
import Pages404 from "Modules/Utility/pages-404";
import InterInstitutionalTransfer from "../Views/InterInstitutionalTransfer";

const TcuRoutes = (props) => {
    return (
        <Routes>
            <Route path="/inter-institutional-transfer" element={<InterInstitutionalTransfer />} />
            <Route path="*" element={<Pages404 />} />
        </Routes>
    );
}

export default TcuRoutes;