import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {useFormik} from "formik";
import { Alert, Card, CardBody, CardSubtitle, Col, Form, Row } from "reactstrap"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import { REQUEST_FEE_STRUCTURE_CONTROL_NUMBERS } from "../../Mutations/StudentPaymentMutations"
import { registrationGraphQLClient, uaaGraphQLClient } from "../../../Authentication/ApolloClient"
import { GET_STUDENT } from "../../../Users/Queries/UserQuery"
import { GET_CONTROL_NUMBER, GET_FEE_STRUCTURE } from "../../Queries/Payment/StudentPaymentQueries"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import { LoaderPage } from "../../../../helpers/LoaderPage"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"

const breadcrumbItem = [
    {
        title: "Control Numbers",
        path: "",
        isActive: true,
    },
]

const ControlNumbers = () => {
    const [Student] = useMutation(REQUEST_FEE_STRUCTURE_CONTROL_NUMBERS, {
        client: registrationGraphQLClient,
    })

    const {
        loading: userLoading,
        error: userError,
        data: userDetails,
        refetch,
    } = useQuery(GET_STUDENT, {
        client: uaaGraphQLClient,
        variables: {
            username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
        },
        fetchPolicy: "network-only",
    })
    const capitalize = text => {
        if (!text) return "" // Handle null or undefined input
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    }

    const user = userDetails?.getUserByUsername?.data

    const studentStatus = user?.student?.status?.name;


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            programUid: user?.student?.programmeUid || "",
            yearOfStudy: parseFloat(user?.student?.studyYear) || 0,
            studentStatus: capitalize(user?.student?.status?.name) || "",
            registrationNumber: user?.student?.registrationNumber || "",
            countrycode: user?.country?.dialCode || "",
            studentName:
              user?.firstName +
              " " +
              user?.middleName +
              " " +
              user?.lastName,
        },

        onSubmit: values => {
            Student({
                variables: {
                    inputs: { ...values },
                },
                refetchQueries: [
                    {
                        client: registrationGraphQLClient,
                        query: GET_CONTROL_NUMBER,
                        variables: {
                            registrationNumber:
                              user?.student?.registrationNumber || "",
                        },
                        fetchPolicy: "cache-first",
                    },
                    {
                        client: registrationGraphQLClient,
                        query: GET_FEE_STRUCTURE,
                        variables: {
                            inputs: {
                                programUid: user?.student?.programmeUid || "",
                                yearOfStudy: user?.student?.studyYear || 0,
                                studentStatus:
                                  capitalize(user?.student?.status?.name) || "",
                                countrycode: user?.country?.dialCode || "",
                            },
                        },
                        fetchPolicy: "cache-first",
                    },
                ],

                onCompleted: ({ requestFeeStructureControlNumbers }) => {
                    if (requestFeeStructureControlNumbers.code === 8000) {
                        // navigate("/students/payments/control_number")
                        validation.resetForm()
                    } else {
                        validation.resetForm()
                    }
                    showToast(
                      requestFeeStructureControlNumbers.message,
                      requestFeeStructureControlNumbers.code === 8000
                        ? "success"
                        : "error"
                    )
                },
                onError: error => {
                    // Handle errors
                    validation.resetForm()
                },
            })
        },
    })

    const {
        loading: fee_loading,
        error: fee_error,
        data: fee_structure,
        refetch: refetchFeeStructures,
    } = useQuery(GET_FEE_STRUCTURE, {
        client: registrationGraphQLClient,
        skip:
          !user?.student?.programmeUid,
        // studentStatus !== "UNREGISTERED" ||
        // user?.student?.studyYear !== 0,
        variables: {
            inputs: {
                programUid: user?.student?.programmeUid || "",
                yearOfStudy: user?.student?.studyYear || 0,
                studentStatus:
                  capitalize(user?.student?.status?.name) || "",
                countrycode: user?.country?.dialCode || "",
            },
        },
        fetchPolicy: "network-only",
    })

    const {
        loading: loadingFeeStructureControlNumbers,
        error: errorFeeStructure,
        data: feeStructureControlNumber,
        refetch: refetchControlNUmbers,
    } = useQuery(GET_CONTROL_NUMBER, {
        client: registrationGraphQLClient,
        skip:
          !user?.student?.registrationNumber,
        variables: {
            registrationNumber: user?.student?.registrationNumber,
        },
        fetchPolicy: "network-only",
    })

    const fees = fee_structure?.getFeeStructure?.data

    const feeWithStructureControlNumber =
      feeStructureControlNumber?.getControlNumbers?.data



    if(userLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching User Details"} />
    if(fee_loading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching Fee Structure Information"} />
    if(loadingFeeStructureControlNumbers) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching Control Number Information"} />


    // console.log("Fees", fees)
    // console.log("controls", feeWithStructureControlNumber)
    return (
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={'Student Control Numbers'}>
          <Row>
              <Col>
                  <Card>
                      <CardBody>
                          <CardSubtitle>
                              <h4>Control Numbers</h4>
                          </CardSubtitle>
                            <Row>
                                <Col md={12}>
                                    <Alert color="info">
                                        If control numbers for the current academic year are not listed below, please click the <b>'Request Control Number'</b> button to generate them.
                                    </Alert>
                                    </Col>
                            </Row>
                          <Row>
                              <Col className="col-9"></Col>
                              <Col className="col-3">
                                  <div className="text-sm-end pull-end">
                                      <div className="mt-3 wizard">
                                          <Form
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return null
                                            }}
                                          >
                                              {fees?.length > 0 && !feeWithStructureControlNumber ? (
                                                <Row>
                                                    <Col lg="12">
                                                        <div className="text-center float-end mt-2 mb-2">
                                                            <SubmitButtonHelper
                                                              type="primary"
                                                              name="Request Control number"
                                                              formik={validation}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                              ) : (
                                                feeWithStructureControlNumber && (
                                                  <Row>
                                                      <Col lg="12">
                                                          <div className="float-end mt-1 mb-1">
                                                              <SubmitButtonHelper
                                                                type="primary"
                                                                name="Request control number"
                                                                formik={validation}
                                                              />
                                                          </div>
                                                      </Col>
                                                  </Row>
                                                )
                                              )}
                                          </Form>
                                      </div>
                                  </div>
                              </Col>
                          </Row>


                          {feeWithStructureControlNumber ? (
                            <>

                                <Table
                                  id="tech-companies-1"
                                  className="table table-striped table-bordered"
                                >
                                    <Thead>
                                        <Tr>
                                            <Th data-priority="1">Fee Name</Th>
                                            <Th data-priority="2">Academic Year</Th>
                                            <Th data-priority="3">Amount</Th>
                                            <Th data-priority="3">Minimum Amount</Th>
                                            <Th data-priority="4">Currency</Th>
                                            <Th data-priority="5">Control Number</Th>
                                        </Tr>
                                    </Thead>
                                    {!loadingFeeStructureControlNumbers && !errorFeeStructure && (
                                      <>
                                          {
                                              feeWithStructureControlNumber ? (
                                                <Tbody>
                                                    {feeWithStructureControlNumber?.map(
                                                      feeWithControlNumbers => {
                                                          const matchingFee = fees
                                                            ? fees?.find(
                                                              payType =>
                                                                payType?.payType ===
                                                                feeWithControlNumbers?.payType
                                                            )
                                                            : ""

                                                          return (
                                                            <Tr key={feeWithControlNumbers?.billId}>
                                                                <Td>{feeWithControlNumbers?.feeName}</Td>
                                                                <Td>{feeWithControlNumbers?.academicYear}</Td>
                                                                <Td  style={{textAlign: "right"}}>{feeWithControlNumbers?.amount.toLocaleString()}</Td>
                                                                <Td  style={{textAlign: "right"}}>{matchingFee?.minAmount.toLocaleString()}</Td>
                                                                <Td className={"text-right"}>
                                                                    {feeWithControlNumbers?.currency}
                                                                </Td>
                                                                {feeWithControlNumbers?.controlNumber != null ? (
                                                                  <Td className={"text-right"}>
                                                                      {feeWithControlNumbers?.controlNumber}
                                                                  </Td>
                                                                ) : (
                                                                  <Td className={"text-right"}>
                                                                      <b className="text-center">Pending...</b>
                                                                  </Td>
                                                                )}
                                                            </Tr>
                                                          )
                                                      }
                                                    )}
                                                </Tbody>
                                              ):(
                                                <>
                                                    <Alert color="danger">
                                                        Failed to retrieve the fee structure from SR2. Please contact the support officer for assistance.                                                    </Alert>
                                                </>
                                              )
                                          }
                                      </>

                                    )}
                                </Table>
                            </>
                          ) : (
                            <>
                                {fees ? (
                                  <Table
                                    id="tech-companies-1"
                                    className="table table-striped table-bordered"
                                  >
                                      <Thead>
                                          <Tr>
                                              <Th data-priority="1">Fee Name</Th>
                                              <Th data-priority="2">Minimum amount</Th>
                                              <Th data-priority="3">Amount</Th>
                                              <Th data-priority="4">Currency</Th>
                                          </Tr>
                                      </Thead>
                                      {!fee_loading && !fee_error && fees !== null && (
                                        <Tbody>
                                            {fees?.map(fee => (
                                              <Tr key={fee.name}>
                                                  <Td>{fee.name}</Td>
                                                  <Td style={{textAlign: "right"}}>{fee.minAmount.toLocaleString()}</Td>
                                                  <Td style={{textAlign: "right"}}>{fee.amount.toLocaleString()}</Td>
                                                  <Td>{fee.currency}</Td>
                                              </Tr>
                                            ))}
                                        </Tbody>
                                      )}
                                  </Table>
                                ) : ""}
                            </>) }
                      </CardBody>
                  </Card>
              </Col>
          </Row>
      </LayoutHelper>
    )
}
export default ControlNumbers