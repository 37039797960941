import React from "react";
import { Route, Routes } from "react-router-dom"
import AsimsDashboard from "../../ASIMS/views/asimsDashboard"
import Pages404 from "../../Utility/pages-404"
import NhifRegistration from "../../Nhif/Views/NhifRegistration"


const NHIFRoutes = () => {
  return (
    <Routes>
      <Route path= "/dashboard" element={<AsimsDashboard />} />
      <Route path="/application"  element={<NhifRegistration />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  );
};
export default NHIFRoutes;
