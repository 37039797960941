import { Link } from "react-router-dom"
import React from "react"

const PaymentLinks = ({ hasAuthority }) => {
  return (
    <>
      <li>
        <Link to="/finance/dashboard">
          <i className="bx bx-home-circle"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      {hasAuthority("VIEW_FEE_STRUCTURE_MENU") && (
          <>
              {hasAuthority("VIEW_FEE_STRUCTURE_MENU") && (
                  <>
                      {hasAuthority("VIEW_FEE_STRUCTURE_MENU") && (
                          <li>
                              <Link to="/finance/fee_structure">
                                  <i className="bx bx-money"></i>
                                  <span>Fee Structure</span>
                              </Link>
                          </li>
                      )}

                      {hasAuthority("VIEW_FEE_STRUCTURE_MENU") && (
                          <li>
                              <Link to="/finance/control_number">
                                  <i className="bx bx-receipt"></i>
                                  <span>Control Numbers</span>
                              </Link>
                          </li>
                      )}

                      {hasAuthority("VIEW_FEE_STRUCTURE_MENU") && (
                          <li>
                              <Link to="/finance/statement">
                                  <i className="bx bx-file"></i>
                                  <span>Financial Statement</span>
                              </Link>
                          </li>
                      )}
                  </>
              )}

              <li>
                  <Link to="/finance/services">
                      <i className="bx bx-money"></i>
                      <span>Other Services</span>
                  </Link>
              </li>
              <li>
                  <Link to="/finance/queries">
                      <i className="bx bx-money"></i>
                      <span>Finance Queries</span>
                  </Link>
              </li>
          </>
      )}
    </>
  )
}

export default PaymentLinks
