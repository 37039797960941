import React from "react"
import {
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { useMutation } from "@apollo/client"
import {
  onlineApplicationGraphQLClient, uaaGraphQLClient
} from "Modules/Authentication/ApolloClient"
import showToast from "helpers/ToastHelper"
import { SEND_TRANSFERED_STUDENT_TO_TCU } from "../Mutations/TcuTransferMutation"
import { GET_COUNTRY } from "../../../Country/Queries/CountryQueries"
import { paginationHelper } from "../../../../helpers/Functions/GraphqlUpdateFunction"

const InterInstitutionalTransfer = () => {
  const topHeader = {
    title: "Inter Institutional Transfers",
    breadcrumbItem: [
      { title: "Inter Institutional Transfers", path: "", isActive: true },
    ],
  }

  const genders = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
  ]


  const [sendTransfersTcu] = useMutation(SEND_TRANSFERED_STUDENT_TO_TCU, {
    client: onlineApplicationGraphQLClient,
  })


  const formik = useFormik({
    initialValues: {
      formFourIndexNo: "",
      formSixIndexNo: "",
      gender: "",
      previousProgramCode: "",
      currentProgramCode: "",
    },
    validationSchema: Yup.object({
      formFourIndexNo: Yup.string().required("This field is required"),
      gender: Yup.string().required("This field is required"),
      previousProgramCode: Yup.string().required("This field is required"),
      currentProgramCode: Yup.string().required("This field is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      sendTransfersTcu({
        variables: {
          inputs: { ...values },
        },
        onCompleted: ({ sendTransfersTcu }) => {
          //updateFunction(client.cache, { data: { registerCountries } });
          if (sendTransfersTcu.code === 8000) {
            formik.resetForm()
          } else {
            setSubmitting(false)
          }
          showToast(
            sendTransfersTcu.message,
            sendTransfersTcu.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  return (
    <LayoutHelper
      breadcrumbItem={topHeader.breadcrumbItem}
      pageTitle={topHeader.title}
    >
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <div className="modal-body">
                  {/* Form Four Index Number */}
                  <Row className="mb-3">
                    <Col md={6}>
                      <Label>Form Four Index Number</Label>
                      <Input
                        type="text"
                        name="formFourIndexNo"
                        className="form-control"
                        placeholder="E.g: S1231/0023/2023"
                        value={formik.values.formFourIndexNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          !!(
                            formik.touched.formFourIndexNo &&
                            formik.errors.formFourIndexNo
                          )
                        }
                      />
                      <FormFeedback>
                        {formik.errors.formFourIndexNo}
                      </FormFeedback>
                    </Col>
                    <Col md={6}>
                      <Label>Form Six Index Number</Label>
                      <Input
                        type="text"
                        name="formSixIndexNo"
                        className="form-control"
                        placeholder="E.g: S1231/0023/2023"
                        value={formik.values.formSixIndexNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Col>
                  </Row>

                  {/* Gender Select */}
                  <Row className="mb-3">
                    <Col md={12}>
                      <Label>Gender</Label>
                      <Select
                        name="gender"
                        options={genders}
                        value={genders.find(
                          option => option.value === formik.values.gender
                        )}
                        onChange={selectedOption =>
                          formik.setFieldValue(
                            "gender",
                            selectedOption?.value || ""
                          )
                        }
                        onBlur={formik.handleBlur}
                        classNamePrefix="select2-selection"
                        className="select2-selection"
                        placeholder="Select Gender"
                      />
                      {formik.touched.gender && formik.errors.gender && (
                        <div className="text-danger mt-1">
                          {formik.errors.gender}
                        </div>
                      )}
                    </Col>
                  </Row>

                  {/* Previous Program Code Select */}
                  <Row className="mb-3">
                    <Col md={6}>
                      <Label>Current Program Code</Label>
                      <Input
                        type="text"
                        name="currentProgramCode"
                        className="form-control"
                        placeholder="Enter student current program code"
                        value={formik.values.currentProgramCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          !!(
                            formik.touched.currentProgramCode &&
                            formik.errors.currentProgramCode
                          )
                        }
                      />
                      <FormFeedback>
                        {formik.errors.currentProgramCode}
                      </FormFeedback>
                    </Col>
                    <Col md={6}>
                      <Label>Previous Program Code</Label>
                      <Input
                        type="text"
                        name="previousProgramCode"
                        className="form-control"
                        placeholder="Enter student previous program code"
                        value={formik.values.previousProgramCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          !!(
                            formik.touched.previousProgramCode &&
                            formik.errors.previousProgramCode
                          )
                        }
                      />
                      <FormFeedback>
                        {formik.errors.previousProgramCode}
                      </FormFeedback>
                    </Col>
                  </Row>
                </div>
                <div className="float-end">
                  <SubmitButtonHelper
                    type="primary"
                    name="submit"
                    formik={formik}
                  />
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default InterInstitutionalTransfer
