import React from "react";
import { Routes, Route } from "react-router-dom"
import UpdatedRegNo from "../Views/UpdateRegNo";
import Pages404 from "Modules/Utility/pages-404";


const ProgramChangeRoutes = (props) => {
    return (
        <Routes>
            <Route path="/update-registration-number" element={< UpdatedRegNo/>} />
            <Route path="*" element={<Pages404 />} />
        </Routes>
    )
}

export default ProgramChangeRoutes;
